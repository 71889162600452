import React from 'react'
import Check from '../assets/CHECKLISTTT.png'

function Checklist() {
  return (
    <div>
      

      <img src={Check} alt="" className='w-auto mr-3 lg:flex hidden' />

    </div>
  )
}

export default Checklist